.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'GaramondItalic';
  src: url('./components/font/Garamond/garmndmi.ttf');
}

@font-face {
  font-family: 'Black Swan BF';
  src: url('./components/font/BlackSwanBF/Black\ Swan\ BF\ W00\ Regular.ttf');
}

@font-face {
  font-family: 'Gabriela Black';
  src: url('./components/font/Gabriela/gabriela-black.otf');
}
@font-face {
  font-family: 'Gabriela Medium';
  src: url('./components/font/Gabriela/gabriela-medium.otf');
}

header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.wrapper {
  height: 100%;
  width: 100%;
  top:0; 
  left:0; 
  /* Setup */
  position: fixed;
  /* opacity: 0.5;  */
  background: #d0e2e1; 
  z-index: 10000;
}

.overlay {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  width: 100%;
  margin: 0 auto;
  /* left: 50%; */
   /* Apply negative top and left margins to truly center the element */
   /* margin: -25px 0 0 -25px; */
}

.btn-mh-1{
  min-height: 130px;
}
.bg-azuloscuro{
  background-color: #205f84;
}
.bg-azulsuave{
  background-color: #b9cbd5;
}
.login-page{
  background-color: inherit;
}
/* dialog {
  margin: 10% auto;
  width: 80%;
  max-width: 350px;
  background-color: #fff;
  padding: 34px;
  border: 0;
  border-radius: 5px;
} */
dialog {
  z-index: 9999;
  padding: 0;
  transition: opacity 0.5s;
  opacity: 0;
  position: fixed;
  pointer-events: none;
}
dialog[open] {
  opacity: 1;
  pointer-events: inherit;
}
dialog header{
  background-color: rgb(216, 216, 216);
  padding: 10px;
}
dialog header button{
  color:white;
  background-color: #dc3545;
  border: none;
  padding: 4px;
  font-weight: bold;
  width: 30px;
  border-radius: 4px;
}
dialog section{
  padding: 16px ;
  background-color: white;
}
dialog, ::backdrop {
  background-color: #00000075;
}
dialog footer{
  background-color: rgb(216, 216, 216);
  padding: 10px;
  text-align: right;
  position: relative;
  z-index: 1000;
}
dialog footer button{
  margin-left: 5px;
}
.modal .capaCarga{
  background: #efefef94;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
.form-row{
  min-height: 27pt;
  margin: 0;
  background-color: rgb(245, 245, 245);
  border-bottom: white solid 2px;
}

.row-button:hover{
  background-color: rgb(233, 254, 243) !important;
  /* outline: rgb(109, 254, 109) solid 1px; */
}

@media (min-width: 992px) {
  .modal-xl {
      max-width: 940px;
  }
}

.img_previa:hover{
  filter:drop-shadow(1px 1px 8px rgb(43, 43, 43));
}